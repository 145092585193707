import printJS from 'print-js'


export const usePrint2 = () => {
  printJS({
    // 需要打印区域设置的Id
    printable: 'print-area',
    // 打印类型
    type: 'html',
    // 默认值为800，我们把把设置为100%
    maxWidth: '100%', 
    // *代表应用所有样式，默认值为null，如果不设置，打印窗口则会忽略所有样式
    // targetStyles: ['*'],
    scanStyle: false,// 是否扫描样式
    // 打印样式
    style:`
    .printarea{
        height:600px;
        padding-right:50px;
        font-family:华文新魏;
    }
    .top{
        height: 300px;
      
        width:100%;
        font-size: 220px;
        font-family:华文新魏;
        line-height: 300px;
        transform: rotate(-180deg); 
        -webkit-transform: rotate(-180deg);
        padding:30px 60px  0 60px;
        
        text-align:justify;
        text-align-last:justify;
          box-sizing: border-box;
        }
      .bottom {
        width:100%;
        font-family:华文新魏;
        height: 300px;
        font-size: 220px;
        line-height: 300px;
        padding:30px 60px  0 60px;
        text-align:justify;
        text-align-last:justify;
        box-sizing: border-box;
        }`   
  });
}


export const usePrint = () => {
  printJS({
    // 需要打印区域设置的Id
    printable: 'print-area',
    // 打印类型
    type: 'html',
    // 默认值为800，我们把把设置为100%
    maxWidth: '100%', 
    // *代表应用所有样式，默认值为null，如果不设置，打印窗口则会忽略所有样式
    // targetStyles: ['*'],
    scanStyle: false,// 是否扫描样式
    // 打印样式
    style:`
    .printarea{
        height:600px;
        padding-right:50px;
        font-family:华文新魏;
    }
    .top{
        height: 300px;
        padding: 30px  0px 0px 0px;
        width:100%;
        font-size: 200px;
        font-family:华文新魏;
        line-height: 300px;
        letter-spacing: 0px;
        transform: rotate(-180deg); 
        -webkit-transform: rotate(-180deg);
        text-align: center;
        }
      .bottom {
        letter-spacing: 0px;
        padding:30px 0px 0px 0;
        width:100%;
        font-family:华文新魏;
        height: 300px;
        font-size: 200px;
        line-height: 300px;
        text-align: center;

      }`   
  });
}