<template>
<div  v-show="false"> 
  <div id="print-area">
    <div class="item" v-for="item in data" :key="item.id">
    
      <div class="main">
        <div class="printarea" >
          <div class="top  con">{{item.label}}</div>
          <div class="bottom con">{{item.label}}</div>
        </div>
      </div>
   
    </div>
  </div>
  </div>
</template>
<script>
export default {
  props: {
    data: Array
  }
}
</script>
<style >
 .printarea{
   width: 100%;
   height: 100%;
   border: 1px solid #000;
 }
 /* .con{
   width: 100%;
   height: 50%;
   display: flex;
   align-items: center;
   justify-content: center;
 } */

</style>