
<template>
  <div class="page">
    <!-- 这里是我们封装的打印组件 -->
    <PrintArea :data="data" />
    <div
      style="
        margin:20px;
        width: 300px;
        display: flex;
        justify-content: space-between;
        align-items: center;
      "
    >
      <el-input
        size="medium"
        v-model="currentName"
        placeholder="请输入打印的姓名"
      />
      <el-button style="margin-left: 10px" @click="onPintCurrent" type="primary"
        >打印当前</el-button
      >
    </div>
    <div class="showarea">
      <div style="width: 300px">
        <el-card header="三字名片历史记录"  style="height: 700px;overflow-y: scroll">
         
          <el-button @click="onPrint">打印</el-button>
         <el-checkbox :value="checkAll" style="margin-top:20px" @change="onCheckAllChange"
            >全选</el-checkbox
          >
          <div style="margin: 15px 0;"></div>
          <el-checkbox-group v-model="checkedItems"  @change="onCheckedChange">
            <el-checkbox
              v-for="item in options"
              :label="item.id"
              :key="item.id"
              >{{ item.label }}</el-checkbox
            >
          </el-checkbox-group>
         
         
        </el-card>
      </div>

      <div style="width: 300px;margin-left:20px">
        <el-card header="二字名片历史记录" style="height: 700px;overflow-y: scroll">
             
          <el-button @click="onPrint2">打印</el-button>
         <el-checkbox :value="checkAll2" style="margin-top:20px" @change="onCheckAllChange2"
            >全选</el-checkbox
          >
          <div style="margin: 15px 0"></div>
          <el-checkbox-group v-model="checkedItems2" @change="onCheckedChange2">
            <el-checkbox
              v-for="item in options2"
              :label="item.id"
              :key="item.id"
              >{{ item.label }}</el-checkbox>
             
          </el-checkbox-group>
          <div style="margin: 15px 0"></div>
         
        </el-card>
      </div>

      <!-- <div style="width: 300px">
        <el-card header="组合名片历史记录" >
          <el-checkbox :value="checkAll" @change="onCheckAllChange"
            >全选</el-checkbox
          >
          <div style="margin: 15px 0;"></div>
          <el-checkbox-group v-model="checkedItems" @change="onCheckedChange">
            <el-checkbox
              v-for="item in options"
              :label="item.id"
              :key="item.id"
              >{{ item.label }}</el-checkbox>
          </el-checkbox-group>
          <div style="margin: 15px 0"></div>
          <el-button @click="onPrint">打印</el-button>
        </el-card>
      </div> -->
    </div>
  </div>
</template>

<script>
import { usePrint, usePrint2 } from "@/hooks/usePrint";

import { add, list } from '@/api/Print.js';

import PrintArea from "@/components/print/PrintArea.vue";
export default {
  components: {
    PrintArea,
  },
  data() {
    return {
      checkedItems: [],
      data:[],
      printData: [],
      currentName: "潘永堂",
      options: [
        { id: 1, label: "潘永堂" },
        { id: 2, label: "潘永堂" }
      ],
      cate:1,
      checkedItems2: [],
      printData2: [],
      options2: [
        { id: 1, label: "潘永" },
        { id: 2, label: "潘堂" }
      ],
    };
  },
  computed: {
    checkAll() {
      return this.checkedItems.length === this.options.length;
    },
    checkAll2() {
      return this.checkedItems2.length === this.options2.length;
    },
    // printData() {
    //   return this.options.filter(item => this.checkedItems.includes(item.id))
    // }
  },
  watch: {
    checkedItems(val) {
      this.printData = this.options.filter((item) =>
        this.checkedItems.includes(item.id)
      );
      this.data = this.printData;
    },
    checkedItems2(val) {
      this.printData2 = this.options2.filter((item) =>
        this.checkedItems2.includes(item.id)
      );
      this.data = this.printData2;
    },
  },
  mounted() {
    this.onList();
  },
  methods: {
    add() {
      add({label: this.currentName,cate:this.cate}).then((res) => {
        this.onList();
      })
    },
    onList() {
      
      list().then((res) => {
        this.options = res.data.option2;
        this.options2 = res.data.option1;
      });
    },

    //打印当前
    onPintCurrent() {
      this.currentName = this.currentName.trim();
      const cname = {
        id: this.options.length + 1,
        label: this.currentName,
      };
      if (this.currentName === "") {
        this.$message.error("请输入打印的姓名");
      } else {
        const len = this.currentName.length;

        if (len === 2) {
          this.cate = 1;
          this.options2.unshift(cname);
          this.checkedItems2 = [cname.id];
          
            
            this.onPrint2();
          
        }

        if (len === 3) {
          this.cate = 2;
          this.options.unshift(cname);
          this.checkedItems = [cname.id];
         
            this.onPrint();
         
        }
      }
      this.add();
    },
    onCheckAllChange() {
      if (this.checkAll) {
        this.checkedItems = [];
      } else {
         this.checkedItems2 = [];
        this.checkedItems = this.options.map((item) => item.id);
      }
    },
    onCheckedChange(val) {
      this.checkedItems = val;
    },
    onPrint() {
      this.data = this.printData;
      this.$nextTick(() => {
        usePrint(); 
      })
     
    },

     onCheckAllChange2() {
      if (this.checkAll2) {
        this.checkedItems2 = [];
        this.checkedItems = [];
      } else {
        this.checkedItems = [];
        this.checkedItems2 = this.options2.map((item) => item.id);
      }
    },
    onCheckedChange2(val) {
      this.checkedItems2 = val;
    },
    onPrint2() {
      this.data = this.printData2;
      this.$nextTick(() => {
        
       usePrint2();
      })
    },
  },
};
</script>
<style lang="scss" >
.showarea {
  display: flex;
  margin-top:20px;
  margin-left:20px;
}
.el-checkbox-group{
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  align-items: center;
  justify-content: flex-start;
}
.el-checkbox{
  display: block;
  margin:0;
  font-size:20px;
}
</style>

