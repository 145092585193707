import request from '@/utils/request'
export function add(data) {
  return request({
    url: '/frontend/bigdata/printname/add',
    method: 'post',
    data
  })
}


export function list(data) {
  return request({
    url: '/frontend/bigdata/printname/list',
    method: 'post',
    data
  })
}
